.title-bar,
.aw-title-bar {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #98012f;
  /* min-height: 50px; */
  background: #f8f9fa;
  padding-right: 20px;
  padding-left: 20px;
  /* flex-grow: 1; */

  /* sticky header */
  position: sticky;
  top: 0;
  z-index: 700;
}

/* .logo-area {
  display: flex;
} */

.logo-area .drop-down-div {
  display: inline-block;
  position: relative;
  /* transform: translate(0px, -50%); */
}

.logo-area .drop-down-div .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  min-width: 40px;
}

.logo-area .drop-down-icon-div .svg-inline--fa.fa-sort-down {
  position: relative;
  top: -2px;
  left: 2px;
}

.logo-area .drop-down-icon-div .svg-inline--fa.fa-sort-down.open {
  transform: rotateX(180deg);
  top:4px;
}

/* .menu-area {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
} */
