.added-col-td.download-col td[class="download-td"] a {
    color: #186ba0;
}

.dashboard-spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.dashboard-top-btns-wrapper {
    display: flex;
    width: 100%;
}

.dashboard-top-btns-wrapper .top-left-btns {
    display: flex;
    width: 50%;
}
.dashboard-top-btns-wrapper .top-left-btns .btn-span {
    margin-right: 10px;
    width: 70px;
}

.dashboard-top-btns-wrapper .top-right-btns {
    display: flex;
    width: 50%;
    justify-content: flex-end;
}