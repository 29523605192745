.fancy-text input[type="text"] {
  border: none;
}

.login-form {
  margin-top: 30px;
}

.login-box p {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.fancy-text input {
  width: 100%;
  margin-bottom: 20px;
}
.fancy-text input[type="text"],
.fancy-text input[type="password"] {
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  outline: none;
  color: #fff;
  height: 40px;
}
.login-form .fancy-text input[type="text"],
.login-form  .fancy-text input[type="password"]  {
  caret-color: white !important;
}


.fancy-text input[type="text"]:-webkit-autofill,
.fancy-text input[type="password"]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px black inset;
  -webkit-text-fill-color: white;
}

.fancy-text input[type="type"]:-moz-autofill,
.fancy-text input[type="password"]:-moz-autofill {
  -moz-box-shadow: 0 0 0 50px black inset;
  -moz-text-fill-color: white;
}

/* white background styling */

.fancy-text.white-bg input[type="text"],
.fancy-text.white-bg input[type="password"] {
  border: none;
  border-bottom: 1px solid #888;
  background: transparent;
  outline: none;
  color: rgb(24, 23, 23);
  height: 40px;
}

.fancy-text input[type="text"]::selection,
.fancy-text input[type="password"]::selection {
  background-color: #3f51b5;
  color: red !important;
}
