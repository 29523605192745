.overlay {
  display: none;
  position: fixed;
  z-index: 800;
  background: rgba(0, 0, 0, 0.8);
  animation-duration: 0.2s;
}

.overlay.open {
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dismiss {
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 36px;
  text-decoration: none;
}

.aw-data-modal.Contacts .modal-dialog {
  min-width: 750px;
}

.aw-data-modal.Contacts .modal-body {
  max-height: 450px;
  min-height: 350px;
  overflow-y: auto;
}

/* The below styles is mainly for notification/license page */
.swal2-container {
  z-index: 1300;
}
.p-dialog-mask.p-dialog-center + div.fade.modal-backdrop.show {
  z-index: 1200;
}

.modal.Notification.Form {
  z-index: 1200;
}