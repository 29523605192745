

.topBar-image-div {
  position: relative;
}

.topBar-image-div div[role="tooltip"] {
  transform: translate3d(-95px, 30px, 0px) !important;
}

ul[role="menu"] {
  padding-top: 0;
  padding-bottom: 0;
  width: 125px;
  /* background: #f8f9fa; */
  border: 1px solid #bfbfbf;
}

ul[role="menu"] a {
  text-decoration: none;
}

ul[role="menu"] a li {
  color: #888;
  /* min-width: 200px !important; */
  min-height: 25px;
  /* line-height: 1.7; */
}

ul[role="menu"] .log-out-btn li {
  border-top: 1px solid #bfbfbf;
}

.topbar-nav-dropdown .nav-dropdown-btn:hover,
.topbar-nav-dropdown .nav-dropdown-btn:focus {
  background: none;
  border: none;
  outline: none;
}
/* .topbar-nav-dropdown .nav-dropdown-btn .MuiButton-label:hover {
} */
