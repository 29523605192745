@import "../../../vars";

.create-password {
    position: relative;
    inset: 0;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pss-wrapper {
        position: relative;
        h1 {
            text-align: center;
        }
        // @include authFormWrapper($minHeight: 300px);
        
        // @include mqrxFavIcon($top: -0.3px);
        @include authFormWrapperWithIcon($minHeight: 280px, $iconTop: -0.3px);
        
        box-shadow: 0 0 2px gray;

        padding: 30px;
        padding-top: 40px;
        transition: all 0.3s ease-out;

        &:hover {
            box-shadow: 1px 1px 3px gray;
        }

        
    }
}
