.fw-content {
  display: flex;
  flex-grow: 1;
  /* background: gray; */
  overflow-x: auto;
}
.content {
  display: flex;
  flex-grow: 1;
  height: 100%;
  padding: 10px 70px;
}

@media (max-width: 768px) {
  .content {
    padding: 10px 30px;
  }
}
