html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe p", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* General Style */
label {
  margin-bottom: 0.5rem;
}




/* General Styles Ends Here */


/* font-awesome icon style */
.svg-inline--fa {
  font-size: 1rem;
}

/* grid styling */
.k-header {
  max-width: 200px;
  /* width: 100px !important; */
}
.p-calendar-w-btn {
  width: 100%;
}
.modal.show + div.p-datepicker {
  /* z-index: 10000 !important; */
}

.modal-primereact-datepicker .p-calendar-w-btn {
  /* width: 86%; */
}
.modal-primereact-datepicker .p-inputtext,
.p-inputtext {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.p-inputtext:enabled:hover {
  border-color: silver !important;
}

.p-button.p-calendar-button {
  background-color: #186ba0;
  transition: background-color 0.2s;
}

.p-widget.p-button:enabled:hover,
.p-widget.p-button:focus {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0;
  color: #fff;
}

ul {
  padding: 0;
}

.aw-no-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.aw-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.aw-btn:not(:disabled) {
  cursor: pointer;
}

/* default styles */
.aw-btn.aw-btn-default {
  color: #186ba0;
  border: 1px solid #186ba0;
}

.aw-btn.aw-btn-default:hover {
  background-color: #186ba0;
  border-color: #186ba0;
  color: #fff;
}

/* secondary styles */
.aw-btn.aw-btn-secondary {
  color: #6c757d;
  border: 1px solid #6c757d;
}
.aw-btn.aw-btn-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

/* danger styles */
.aw-btn.aw-btn-danger {
  color: #dc3545;
  border: 1px solid #dc3545;
}

.aw-btn.aw-btn-danger:hover {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

/* disabled buttons */
.aw-btn.aw-btn-danger.disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}
.aw-btn.aw-btn-default.disabled {
  background-color: #186ba0;
  border-color: #186ba0;
}
.aw-btn.aw-btn-secondary.disabled {
  background-color: #6c757d;
  border-color: #6c757d;
}
.aw-btn.disabled {
  cursor: not-allowed;
  color: #fff;
}

/* general page styling */
/* .added-col-td .svg-inline--fa {
  font-size: 1.3rem;
} */
.aw-grid .k-grid-container .svg-inline--fa {
  font-size: 1rem;
  cursor: pointer;
}

.aw-grid .k-grid-header .svg-inline--fa {
  font-size: 1.25rem;
}

/* icons */
.aw-icons {
  display: inline-block;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.aw-icons:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.2);
}
.aw-icons:focus {
  outline: none;
}
/* state page styling */
/* this is for awtab stylings */
.aw-state-page-tabview-section
  .aw-state-tabview-page
  .p-tabview
  .p-tabview-panels,
body .p-tabview .p-tabview-panels {
  border: none !important;
  padding: 2em 0 0 0 !important;
}


.aw-state-tabview-page .p-tabview-nav-content {
  display: inline-block !important;
}



.pre-app > .loader {
  /* position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  background: white !important; */
  animation: none;
  border: none;
}

/* swal styling override */
.swal2-container .swal2-header .swal2-title {
  font-size: 1.8rem;
}
.swal2-container .swal2-content {
  font-size: 1.1rem;
}
.swal2-container .swal2-actions button[type="button"] {
  /* display: none; */
  font-size: 0.9rem;
  padding: 7px 10px;
}
.swal2-container .swal2-actions button[type="button"]:focus,
.swal2-container .swal2-actions button[type="button"]:active {
  box-shadow: none;
}

.swal2-container .swal2-actions .swal2-icon {
  font-size: 0.8rem;
}

.swal2-popup.swal2-modal.aw-html .swal2-content {
  text-align: left;
}





.cart {
  width: 100%;
  border: 1px solid red;
}
.parentCell{ 
  position: relative; 
} 
.cart td{ 
  border: 1px solid black; 
  padding: 3px; 
} 

div[role="tooltip"] {
  z-index: 100;
  /* top: -10% !important;
  left: -1% !important; */
  transform: translate3d(8px, 27px, 0px) !important;
  color: #888;
}
  

.modal-dialog {
  /* top: 6% !important; */
}
.modal {
  z-index: 800;
}
.modal-backdrop.show {
  z-index: 799;
}

.with-icon > input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.with-icon > input:focus {
  border-color: #85b7d9 !important;
  background: #fff;
  color: rgba(0,0,0,.8);
  box-shadow: none !important;
}

.with-icon > button {
  background-color: rgb(24, 107, 160);
}
.with-icon > button:hover {
  background: rgb(28, 108, 158) !important;
  color: #ffffff;
  border-color: rgb(28, 108, 158) !important;
}

.with-icon > button:focus {
  border-color: #85b7d9 !important;
  box-shadow: none !important;
}