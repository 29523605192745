.admin-page {
  display: flex;
  width: 100%;
  margin-top: 1.3em;
}

.admin-page .card-wrapper {
  /* flex-grow: 1; */
  width: 100%;
}

.admin-page .card-wrapper.user {
  margin-right: 3em;
  flex-grow: 3;
  width: 70%;
}


.admin-page .card-wrapper.role {
  width: 30%;
}


/* usergrid settings */
.grid-dd-phone-btn {
      padding: 0.2rem 0.9rem;
    display: inline-block;
}
/* 
.grid-dd-phone-btn .def {
  display: none;
}
.grid-dd-phone-btn:hover .def {
  display: block;
  position: absolute;
} */










@media only screen and (max-width: 1460px) {
  .admin-page .card-wrapper.user {
    max-width: 60%;
  }

  .admin-page .card-wrapper.role {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 1247px) {
  .admin-page {
    display: flex;
    flex-direction: column;
  }
  .admin-page .card-wrapper.user {
    min-width: 100%;
    margin-bottom: 3em;
  }

  .admin-page .card-wrapper.role {
    min-width: 100%;
  }
}





.su-actions span:nth-child(2n+0),
.su-d-actions span:nth-child(2n+0)
 {
  margin: 0px 18px;
}
