.asap-actions .svg-inline--fa {
  font-size: 1.3rem;
}

.asap-actions .svg-inline--fa:hover {
  cursor: pointer;
}

.asap-actions .asap-edit-i:hover {
  color: #186ba0;
}

.asap-actions .asap-delete-i:hover {
  color: #d01919;
}
