/* General styling */
.orders small {
    /* .orders .unreported-order-summary > div  */
    color: red;
    font-size: 14px;
    margin-bottom: 1rem;
}
.orders .unreported-order-summary > div {
    margin: 1rem 0;
}

.orders .unreported-order-summary > div,
.orders .unreported-order-summary > div .dates,
.orders .unreported-order-summary > div .dates .horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orders .unreported-order-summary > div .dates .aw-btn.aw-btn-default {
    line-height: 1.4;
    font-size: 1rem;
    margin-left: 0.5rem;
}


/* .orders .unreported-order-summary > div .dates > button {
    margin-left: 20px;
} */

.orders .unreported-order-summary > div .dates .horizontal {
    width: 150px;
}
.orders .unreported-order-summary > div .dates .p-calendar-w-btn {
    width: 100%
}
.orders .unreported-order-summary > div .dates .p-calendar.p-calendar-w-btn .p-inputtext {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-right: 1px solid #a6a6a6!important;
    line-height: 0;
}

.orders .unreported-order-summary > div .dates .p-calendar.p-calendar-w-btn input {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}


/* .modal-primereact-datepicker .p-inputtext, .p-inputtext {
    line-height: 0
} */

.orders .unreported-order-summary > div .dates .p-calendar-w-btn button[type="button"] {
    display: none;
}


.orders .unreported-order-summary > div .dates .horizontal > label {
    /* display: none; */
    /* margin-bottom: 0;
    margin-right: 0.5rem; */
    margin: 0 0.5rem;
}


/* Weekly Report styling */
.weekly-report .search {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

/* .weekly-report .search small {
    color: red;
    font-size: 14px;
} */

.weekly-report .search small > span {
    color: black;
}

.weekly-report .search select {
    width: 6rem;
    padding: 0.5rem 0.7rem ;
    cursor: pointer;
}