.segment-card-header-row {
  margin-bottom: 1rem;
}
.seg_card_content_created {
  position: relative;
  top: -0.6rem;
}
.seg_card_content_created
  .p-datepicker.p-input-overlay.p-shadow.p-input-overlay-visible {
  left: inherit !important;
  right: 0 !important;
}

.edit-field-row .text-danger.asterisk-for-diffValue {
  font-size: 2.3rem;
  position: absolute;
  /* right: 0; */
  top: 0;
  top: 50%;
  transform: translate(10%, -10%);
}
