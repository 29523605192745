@import "../../../vars";


.full-screen {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(45deg, rgba(48, 47, 47, 0.5), rgba(60, 59, 59, 0.5), rgba(60, 59, 59, 0.5), transparent, transparent), url("../../../assets/images/medquest-building.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.paper {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* avatar {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }, */




.sign-in-header,
.login-form {
  margin-top: 2rem !important;
}

.sign-in-header {
  text-align: center;
}

.login-form {
  width: 100%;
}

.forgot-password-div {
  display: flex;
  justify-content: center;

  margin: 6% auto;
}
.forgot-password-div span {
  transition: all 0.5s;
}
.forgot-password-div span:hover {
  color: #c7c1c1;
}

.google-auth-btn-div {
  margin-top: 20px;
  display: flex !important;
}

.google-auth-btn-div button[type="button"] {
  flex-grow: 1;
}

.google-auth-btn-div button[type="button"]:disabled,
.MuiButton-contained.Mui-disabled.submit-btn {
  /* pointer-events: none; */
  cursor: not-allowed !important;
}

.submit-btn {
  margin-bottom: 10px !important;
}
.MuiButton-contained.Mui-disabled.submit-btn {
  /* background-color: rgba(0, 0, 0, 0.12); */
  background-color: #303f9f;
  color: rgb(196, 194, 194) !important;
}



.wrapper {
  position: relative;
  
  @include authFormWrapperWithIcon();
  
  padding-bottom: 0;
}
.wrapper p {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.wrapper.forgot-psswd-wrapper {
  min-height: 200px;
}

.fg-pwd-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: rgba(0,0,0,0.56);
  overflow: hidden;
  z-index: 20;
  &:hover {
    color: rgba(0,0,0,0.3);
  }

   > span {
    z-index: 1;
    color: rgba(0,0,0,0.5);
    transition: 0.5s;
    position: relative;
    top: 10px;
    &:hover {
      color: rgba(0,0,0,0.9);
    }
  }
}

.fg-pwd-container::after {
    content: '';
    --w-size: 100px;
    // --w-bg: #98012e;
    --w-bg: #ddd;
    width: calc(3 * var(--w-size));
    height: calc(0 * var(--w-size));
    background-color: var(--w-bg);
    border: var(--w-size) solid var(--w-bg);
    border-bottom-color: transparent;
    border-radius: 50%;
    position: absolute;
    bottom: calc(var(--w-size) * -1.5);
}
.fg-pwd-container::after:hover .fg-pwd-container::after {
  box-shadow: 0 0 2px green;
  display: none;
}


/* 
.wrapper::after {
  content: '';
  background: white;
  position: absolute;
  width: calc(100% - 60px);
  bottom: 0;
  border: 30px solid black;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
} */



@keyframes glowSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}