:root {
    --label-active-clr: black;
    --label-hover-clr: #555;
}



.v-tab-container {
    display: flex;
    justify-content: center;
}

.v-tab-container .v-tabs {
    /* width: 25%; */
    /* min-width: 110px; */
    max-width: 150px;
    margin-right: 2rem;
}

.v-tab-container .v-content {
    width: 75%;
}


.v-tab-container .v-tabs .v-tab {
    /* border: 1px solid red; */
    padding: 1.2rem 1rem;
    min-width: 120px;
    transition: all 0.3s;
}

.v-tab-container .v-tabs .v-tab-wrap {

}
.v-tab-container .v-tabs .v-tab-wrap input + label {
    box-shadow: 0 3px 1px var(--label-hover-clr);
    cursor: pointer;
}


.v-tab-container .v-tabs .v-tab-wrap input {
    display: none;
}
.v-tab-container .v-tabs .v-tab-wrap input:checked + label {
    background: var(--label-active-clr);
    color: white;
    box-shadow: none;
}
.v-tab-container .v-tabs .v-tab-wrap input:not(:checked) + label:hover {
    background: var(--label-hover-clr);
    color: white;
    box-shadow: none;
}


