.animated-text-field {
    // $input-bg: #eee;
    $input-bg: #e6eefc;
    $input-clr: black;

    position: relative;
    width: 100%;
    height: 60px;
    // border: 1px solid red;
    margin-bottom: 15px;
    

    > input {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px 10px 10px;
        border: none;
        outline: none;
        background: transparent;
        transition: 0.5s;
        z-index: 2;
        // border: 1px solid green;
    }

    > span {
        // padding: 10px 0px 10px;
        
        position: absolute;
        left: 0;
        top: 50%;
        color: $input-clr;
        opacity: 0.5;
        font-size: 1.1rem;
        letter-spacing: 0.07em;
        transition: 0.5s;
        > em {
            opacity: 0.8;
            color: $input-clr !important;
            transition: 0.5s;
        }
    }

    > div {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1.2px;
        background: darken($color: $input-bg, $amount: 10);
        opacity: 0.8;
        border-radius: 4px;
        transition: 0.5s;
    }

    > input:focus,
    > input:valid,
    > input:-webkit-autofill,
    > input:-webkit-autofill:hover,
    > input:-webkit-autofill:focus,
    > input:-webkit-autofill:active  {
        outline: none;
        background-color: transparent !important;
    }
    

    > input:focus ~ span,
    > input:valid ~ span {
        opacity: 1;
        font-size: 0.9rem;
        top: 0;
        // transform: translateY(-20px);
    }
    > input:focus ~ span > em,
    > input:valid ~ span > em {
        opacity: 1;
    }

    > input:focus ~ div,
    > input:valid ~ div {
        opacity: 1;
        height: 38px;
        background: lighten($color: $input-bg, $amount: 0);
        // #e6eefc
        // height: 80%;
    }
}