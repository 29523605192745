.dashboard-grid-select {
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

/* forms styling */
.dashboard-grid-select:focus {
  box-shadow: none !important;
  outline-offset: 0px !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}

.dashboard-grid-select.form-control {
  height: calc(1.5rem + 2px) !important;
}
/* .aw-select-spantag-wrapper {
    display: block;
    position: relative;
    font-family: Arial;
    width: 100%;
}
.aw-select-spantag-wrapper select {
    display: none;
}


.select-selected {
    background-color: DodgerBlue;
  }
  
  .select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }
  
  
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  }
  
  
  .select-items div,.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
  }
  
  
  .select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }
  
  
  .select-hide {
    display: none;
  }
  
  .select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  } */
