/* html progress bar */
progress[value] {
    /* Styling here */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 3px;
  
    /* position: absolute; */
    position: fixed;
    z-index: 10000000;
    transition: width 8s ease;
  }
  
  progress[value]::-webkit-progress-bar {
    /* Styling here */
    background-color: whitesmoke;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    background: red;
  }
  