// variables
$app-primary-clr: #98012e;
@mixin responsiveMaxWidth($bp: 500px) {
  @media screen and (max-width: $bp) {
    @content;
  }
}
@mixin authFormWrapperWithIcon($bg-clr: white, $minHeight: 330px, $iconTop: -1px) {
  @include authFormWrapper($bg-clr: $bg-clr, $minHeight: $minHeight);
  @include mqrxFavIcon($bg-clr: $bg-clr, $top: $iconTop);
}

@mixin authFormWrapper($bg-clr: white, $minHeight: 330px) {
    width: 380px;
    min-height: $minHeight;
    background: $bg-clr;
    color: inherit;
    box-sizing: border-box;
    padding: 30px;
    border-radius: 15px;
    opacity: 0.99;
    @include responsiveMaxWidth() {
      width: 280px;
      padding: 15px 15px 0;
      
      h1 {
        margin: 25px 0 0 !important;
      }
    }
}


@mixin mqrxFavIcon($bg-clr: white, $top: -1px) {
    &::before {
        // content: url("../../../assets/images/mq-favicon.png");
        content: url("./assets/images/mq-favicon.png");
        background: $bg-clr;
        padding: 0.3rem;
        padding-bottom: 0;
        position: absolute;
        left: 50%;
        top: calc(#{$top} + 2px);
        transform: translateX(-50%);
        z-index: 1;
        width: 31px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include responsiveMaxWidth() {
          left: 51.6%;
        }
      }

      &::after {
        content: "";
        background: linear-gradient(var(--fav), $app-primary-clr, $app-primary-clr, white, white);
        position: absolute;
        top: $top;
        left: 0;
        width: 35px;
        height: 35px;
        border: 1px solid white;
        animation: favAn 5s linear infinite;
        left: 45.4%;
        z-index: 0;
      }
}

@property --fav {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
  }
  @keyframes favAn {
    0% {
      --fav: 0deg;
    }
    100% {
      --fav: 360deg;
    }
  }