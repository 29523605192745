/* horizontal styling */
.fw-menu.horizontal-menu.active,
.fw-menu.vertical-menu.active {
  background-color: #ff6357;
  transition: all 0.5s linear;
}
.fw-menu.horizontal-menu.active .MuiListItem-root,
.fw-menu.horizontal-menu.active .list-item-icon,
.fw-menu.vertical-menu.active .MuiListItem-root,
.fw-menu.vertical-menu.active .list-item-icon {
  color: white;
}

.fw-menu.vertical-menu .list-item-icon {
  color: #ccc;
}
.fw-menu.vertical-menu .MuiListItem-root {
  color: #ccc;
  padding: 5px 8px 5px 30px !important;
}

.fw-menu.vertical-menu:hover .MuiListItemText-root,
.fw-menu.vertical-menu:hover .list-item-icon {
  color: white !important;
}
