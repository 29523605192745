.ks-add-cv {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.ks-add-cv #btnGroupAddon:hover {
    cursor: pointer;
}

.ks-form-btns {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0.1rem;
}

.ks-form-btns .cancel {
    margin-right: 0.5rem;
}

.ks-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
}

.ks-list-item .ks-delete {
    color: red;
    margin-right: 2rem;
    transition: all 0.5s ease;
}

.ks-list-item .ks-delete svg {
    font-size: 1.3rem;
}

.ks-list-item .ks-delete:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.m-l-btn-div.more {
    position: absolute;
    bottom: 0px;
    display: block;
    width: 100%;
    background: white;
    text-align: right;
    padding: 0.75rem;
}

.m-l-btn-div.less {
    position: relative;
    bottom: 0px;
    display: block;
    width: 100%;
    background: white;
    text-align: right;
    padding: 0.75rem;
}

.doccard-nav .doccard-nav-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    padding-right: 0.75rem;
}

.doccard-nav .doccard-nav-btns span {
    margin: 0 2rem;
}

.kw.btn {
    transition: all 0.3s;
}

.kw.btn:not(:hover) {
    background: none;
    color: #212529;
}

.kw.btn.btn-danger:not(:hover) {
    color: #dc3545;
}
.kw.btn.btn-warning:hover {
    background: #ffc107;
    border-color: #ffc107;
}


