.topBar-image-div {
    display: inline-block;
}
.topBar-image-div .MuiButtonBase-root.MuiButton-root.MuiButton-text{
    padding: 0;
    min-width: 20px;
}


.initials {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid;
}